<template>
  <div class="columns is-marginless is-multiline">
    <div class="column is-4 is-12-touch">
      <div class="columns is-multiline">
        <div class="column is-12">
          <reseller-settings :reseller-id="userId" />
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-12">
          <reseller-reports :reseller-id="userId" />
        </div>
      </div>
    </div>
    <div class="column is-8 is-12-touch">
      <div class="columns is-multiline">
        <div class="column is-12">
          <collapse title="Clients">
            <reseller-clients :reseller-id="userId" />
          </collapse>
        </div>
        <div class="column is-12">
          <collapse title="Sites">
            <reseller-sites />
          </collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResellerControls",
  components: {
    "reseller-clients": () => import("@shared/reseller/_clients"),
    "reseller-settings": () => import("@shared/reseller/_settingsCard"),
    "reseller-sites": () => import("@shared/reseller/_sites"),
    "reseller-reports": () => import("@shared/reseller/_reports")
  },
  data() {
    return {
      routes: [
        {
          label: "Reseller controls",
          to: { path: "/reseller" }
        }
      ]
    };
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    }
  },
  mounted() {
    this.$bus.$emit("scrollToTop");
  }
};
</script>
